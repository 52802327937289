import { MODAL_TYPE_MOVE_POST } from './move-post-modal/move-post-modal-type';
import { MODAL_TYPE_DELETE_POST } from './delete-post-modal/delete-post-modal-type';
import { MODAL_TYPE_DISCARD_POST } from './discard-post-modal/discard-post-modal-type';
import { MODAL_TYPE_REPORT_POST } from './report-post-modal/report-post-modal-type';
import { MODAL_TYPE_UNFOLLOW_CATEGORY } from './unfollow-category-modal/unfollow-category-modal-type';
import { MODAL_TYPE_CREATE_POST } from './post-create-modal/post-create-modal-type';
import { MODAL_TYPE_EDIT_POST } from './post-create-modal/post-edit-modal-type';
import { MODAL_TYPE_DELETE_CATEGORY } from './delete-category-modal/delete-category-modal-type';
import { MODAL_TYPE_DEMO_MODE } from './demo-modal/demo-modal-type';
import { MODAL_TYPE_MOVE_POST_CONFIRMATION } from './move-post-confirmation-modal/move-post-confirmation-modal-type';
import { MODAL_TYPE_FILTER_AND_SORT } from './filter-and-sort-modal/filter-and-sort-modal-type';
import { MODAL_TYPE_DISCONNECT_PLAN } from './disconnect-plan-modal/disconnect-plan-modal-type';
import { MODAL_TYPE_WHO_LIKED_LIST } from './who-liked-list-modal/who-liked-list-modal-type';
import {
  MODAL_TYPE_CATEGORY_ACTIONS,
  MODAL_TYPE_CATEGORY_MANAGER_ACTIONS,
  MODAL_TYPE_COMMENT_ACTIONS,
  MODAL_TYPE_CREATE_POST_ACTIONS_MOBILE,
  MODAL_TYPE_FORUM_ACTIONS,
  MODAL_TYPE_POST_ACTIONS,
} from './action-sheet/action-sheet-modal-types';
import { MODAL_TYPE_SEARCH_RESULTS_SORT } from './search-results-sort-modal/search-results-sort-modal-type';
import { MODAL_TYPE_UNFOLLOW_ALL_CATEGORIES } from './unfollow-all-categories-modal/unfollow-all-categories-modal-type';
import { loadLazyComponent } from '@wix/communities-forum-client-commons';
import { MODAL_TYPE_POSTING_DISABLED } from './posting-disabled-modal/posting-disabled-modal-type';
import { MODAL_TYPE_PIN_POST } from './pin-post-modal/pin-post-modal-type';

const WhoLikedListModalComponent = loadLazyComponent(() =>
  import(
    /* webpackChunkName: "who-liked-list-modal" */
    './who-liked-list-modal/who-liked-list-modal-component'
  ),
);

const MovePostModal = loadLazyComponent(() =>
  import(
    /* webpackChunkName: "move-post-modal" */
    './move-post-modal/move-post-modal-component'
  ),
);
const DeletePostModal = loadLazyComponent(() =>
  import(
    /* webpackChunkName: "delete-post-modal" */
    './delete-post-modal/delete-post-modal-component'
  ),
);
const DeleteCategoryModal = loadLazyComponent(() =>
  import(
    /* webpackChunkName: "delete-category-modal" */
    './delete-category-modal/delete-category-modal-component'
  ),
);
const DiscardPostModal = loadLazyComponent(() =>
  import(
    /* webpackChunkName: "discard-post-modal" */
    './discard-post-modal/discard-post-modal-component'
  ),
);
const ReportPostModal = loadLazyComponent(() =>
  import(
    /* webpackChunkName: "report-post-modal" */
    './report-post-modal/report-post-modal-component'
  ),
);
const UnfollowCategoryModal = loadLazyComponent(() =>
  import(
    /* webpackChunkName: "unfollow-category-modal" */
    './unfollow-category-modal/unfollow-category-modal-component'
  ),
);
const PostCreateModal = loadLazyComponent(() =>
  import(
    /* webpackChunkName: "post-create-modal" */

    './post-create-modal/post-create-modal-component'
  ),
);
const PostEditModal = loadLazyComponent(() =>
  import(
    /* webpackChunkName: "post-edit-modal" */

    './post-create-modal/post-edit-modal-component'
  ),
);
const DemoModal = loadLazyComponent(() =>
  import(
    /* webpackChunkName: "demo-modal" */

    './demo-modal/demo-modal-component'
  ),
);
const MovePostConfirmationModal = loadLazyComponent(() =>
  import(
    /* webpackChunkName: "move-post-confirmation-modal" */
    './move-post-confirmation-modal/move-post-confirmation-modal-component'
  ),
);
const FilterAndSortModal = loadLazyComponent(() =>
  import(
    /* webpackChunkName: "filter-and-sort-plan-modal" */
    './filter-and-sort-modal/filter-and-sort-modal-component'
  ),
);
const DisconnectPlanModal = loadLazyComponent(() =>
  import(
    /* webpackChunkName: "disconnect-plan-modal" */
    './disconnect-plan-modal/disconnect-plan-modal-component'
  ),
);
const ActionSheetModal = loadLazyComponent(() =>
  import(
    /* webpackChunkName: "action-sheet-modal" */
    './action-sheet/action-sheet-modal-component'
  ),
);
const SearchResultsSortModal = loadLazyComponent(() =>
  import(
    /* webpackChunkName: "search-results-sort-modal" */
    './search-results-sort-modal/search-results-sort-modal-component'
  ),
);

const UnfollowAllCategoriesModal = loadLazyComponent(() =>
  import(
    /* webpackChunkName: "unfollow-all-categories-modal" */
    './unfollow-all-categories-modal/unfollow-all-categories-modal-component'
  ),
);

const PostingDisabledModal = loadLazyComponent(() =>
  import(
    /* webpackChunkName: "unfollow-all-categories-modal" */
    './posting-disabled-modal/posting-disabled-modal-component'
  ),
);

const PinPostModal = loadLazyComponent(() =>
  import(
    /* webpackChunkName: "pin-post-modal" */
    './pin-post-modal/pin-post-modal-component'
  ),
);

export const modalComponentMapByType = {
  [MODAL_TYPE_CREATE_POST]: PostCreateModal,
  [MODAL_TYPE_DELETE_POST]: DeletePostModal,
  [MODAL_TYPE_DELETE_CATEGORY]: DeleteCategoryModal,
  [MODAL_TYPE_DEMO_MODE]: DemoModal,
  [MODAL_TYPE_DISCARD_POST]: DiscardPostModal,
  [MODAL_TYPE_EDIT_POST]: PostEditModal,
  [MODAL_TYPE_FILTER_AND_SORT]: FilterAndSortModal,
  [MODAL_TYPE_MOVE_POST]: MovePostModal,
  [MODAL_TYPE_MOVE_POST_CONFIRMATION]: MovePostConfirmationModal,
  [MODAL_TYPE_REPORT_POST]: ReportPostModal,
  [MODAL_TYPE_UNFOLLOW_CATEGORY]: UnfollowCategoryModal,
  [MODAL_TYPE_DISCONNECT_PLAN]: DisconnectPlanModal,
  [MODAL_TYPE_WHO_LIKED_LIST]: WhoLikedListModalComponent,
  [MODAL_TYPE_CATEGORY_ACTIONS]: ActionSheetModal,
  [MODAL_TYPE_FORUM_ACTIONS]: ActionSheetModal,
  [MODAL_TYPE_POST_ACTIONS]: ActionSheetModal,
  [MODAL_TYPE_CATEGORY_MANAGER_ACTIONS]: ActionSheetModal,
  [MODAL_TYPE_COMMENT_ACTIONS]: ActionSheetModal,
  [MODAL_TYPE_CREATE_POST_ACTIONS_MOBILE]: ActionSheetModal,
  [MODAL_TYPE_SEARCH_RESULTS_SORT]: SearchResultsSortModal,
  [MODAL_TYPE_UNFOLLOW_ALL_CATEGORIES]: UnfollowAllCategoriesModal,
  [MODAL_TYPE_POSTING_DISABLED]: PostingDisabledModal,
  [MODAL_TYPE_PIN_POST]: PinPostModal,
};
